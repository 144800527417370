.navbar-1 {
    z-index: -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: auto;
}


.mlr {
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 100%;
    margin: 0 20px 0 50px;

    @media (max-width: 472px) {
        flex-direction: column;
        margin-top: 10px;
        margin-bottom: 10px;
        margin: 10px;
    }
}

.navbar-2 {

    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    height: 40px;

    ul {
        padding-left: 11px;
        margin-top: 1rem;

        @media (max-width: 472px) {
            margin: 0;
        }
    }
}

.persona-text p {
    @media (max-width: 472px) {
        margin: 0;
    }
}

.custom-select {
    background-image: url('../../public/img/arrow-down.svg');
    background-size: 10px;
}

.selector {
    cursor: pointer;
    border: 1px solid #D5D7E3;
    border-radius: 6px;
    color: #D5D7E3;
    font-size: 14px;
    letter-spacing: 0.15px;
    width: 257px;
    height: 42px;

    option {
        color: black;
        font-size: 14px;
        letter-spacing: 0.15px;
        cursor: pointer;
    }
}

.navbar-two {
    z-index: -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: auto;
}