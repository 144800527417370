.hiddenError {
    display: none;
}

.error {
    width: 320px;
    background: #F0142F;
    border-radius: 3px;
    height: 42px;
    margin: auto;
    display: flex;
    align-items: center;
    padding-right: 10px;
    padding-left: 10px;

    .error-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .msg {
        p {
            color: #fff;
            margin: 0;
        }
    }
}

.close-icon {
    cursor: pointer;
    background-image: url('../../public/img/close.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    margin-top: 8px;
}

.mt-20 {
    margin-top: 20px;
}

.login-page {
    width: 100%;
    height: 100vh;
    // background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-box {
    width: 321px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.login-logo .heading {
    color: #2D333A;
    font-size: 35px;
    text-align: center;
}

.login-logo .heading_white {
    color: #fff;
    font-size: 35px;
    text-align: center;
}

.login-logo .para {
    color: #6F7984;
    letter-spacing: 0.15px;
    padding-top: 14px;
    width: 359px;
    padding-left: 9px;
}

.login-logo .para_white {
    color: #fff;
    letter-spacing: 0.15px;
    padding-top: 14px;
    width: 359px;
    padding-left: 9px;
}

.login-box .card {
    border: none;
    display: flex;
    flex-direction: column;

    .card-body {
        flex: 1 1 auto;
    }

    .card-body_dark {
        flex: 1 1 auto;
        background: #5d5e6f;
        -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1.25rem;

        .password-match {
            border: 2px solid green;
            border-radius: 3px;
            padding: 10px;
            width: 320px;
            height: 52px;
            margin-bottom: 25px;
        }
    }

    .email-input {
        border: 1px solid #C2C8D0;
        border-radius: 3px;
        padding: 10px;
        width: 320px;
        height: 52px;
        margin-bottom: 25px;
        // try
        outline: none;

        &::placeholder {
            color: #6F7984;
            font-size: 18px;
            letter-spacing: 0.2px;
        }
    }

    .btn-continue {
        box-shadow: none;
        background: #10A37F 0% 0% no-repeat padding-box;
        border-radius: 3px;
        color: #fff;
        width: 320px;
        height: 52px;
        // margin-top: 25px;
        font-size: 18px;
        letter-spacing: 0.2px;
    }

    .sign-para {
        margin-top: 25px;

        p {
            font-size: 16px;
            letter-spacing: 0.18px;
            color: #6F7984;
            text-align: center;

            span {
                color: #10A37F;
                letter-spacing: 0.18px;
                cursor: pointer;
                font-size: 16px;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.card .card-body-bottom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;

    .option {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 35px;

        p {
            margin-top: 15px;
            color: #6F7984;
            font-size: 15px;
            letter-spacing: 0.17px;
        }
    }
}

.hr-left {
    border-top: 1px solid #C2C8D0;
    width: 121px;
    height: 1px;
    margin-right: 20px;
}

.hr-right {
    border-top: 1px solid #C2C8D0;
    width: 133px;
    height: 1px;
    margin-left: 20px;
}

.half-input {
    width: 140px;
    border: 1px solid #C2C8D0;
    border-radius: 3px;
    padding: 10px;
    height: 52px;
    margin-bottom: 25px;
}

.select-country {
    width: 65px;
    border: 1px solid #C2C8D0;
    border-radius: 3px;
    padding: 10px;
    height: 52px;
    margin-bottom: 25px;
}

.phone {
    width: 232px;
    border: 1px solid #C2C8D0;
    border-radius: 3px;
    padding: 10px;
    height: 52px;
    margin-bottom: 25px;
}

.phone-number-div {
    width: 418px;
}

.overflow-y-auto {
    overflow-y: auto;
}

.mt-206 {
    margin-top: 206px;
}

.login-box .card .password-mismatch {
    border: 1px solid red;
    border-radius: 3px;
    padding: 10px;
    width: 320px;
    height: 52px;
    margin-bottom: 25px;

    &::placeholder {
        color: #6F7984;
        font-size: 18px;
        letter-spacing: 0.2px;
    }

    &:focus-visible {
        outline: none;
        // outline: 0.5px solid #cadfff;
        // border: none;
    }
}

.login-box .card .password-match {
    border: 1px solid green;
    border-radius: 3px;
    padding: 10px;
    width: 320px;
    height: 52px;
    margin-bottom: 25px;

    &::placeholder {
        color: #6F7984;
        font-size: 18px;
        letter-spacing: 0.2px;
    }

    &:focus-visible {
        outline: none;
        // outline: 0.5px solid #cadfff;
        // border: none;
    }
}