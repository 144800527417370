.model-li {
    list-style: none;
}

.code-header {
    display: flex;
    background: #343541;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 15px;

    p {
        font-weight: 100;
        font-size: 15px;
    }

    button {
        background-color: #343541;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        text-align: center;

        .copy {
            height: 15px;
            padding-left: 0;
            padding-top: 0px;
        }

        p {
            color: #fff;
            font-weight: 100;
            padding-bottom: 1px;
            font-size: 15px;
        }
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
}

.loader-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable {
    display: none;
}


.main-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.dark-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    background: #343541;
}

.dashboard-main-content {
    scrollbar-color: #555555 #f0f0f0;
}

/* The scrollbar track */
.dashboard-main-content::-webkit-scrollbar {
    width: 7px;
    background-color: #f0f0f0;
}

.dashboard-main-content::-webkit-scrollbar-thumb {
    background-color: #555555;
}

.dashboard-main-content::-webkit-scrollbar-thumb:hover {
    background-color: #888888;
}

.dashboard {
    width: 100%;
}

.dashboard-main-content {
    height: 77vh;
    overflow-y: auto;
    // margin-bottom: 100px;
}

.chatgpt-div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    margin-left: 12px;
}

.user-image_div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatgpt-div_icon {
    width: 34px;
    height: 34px;
    background-image: url('../../public/img/chatgpt1.png');
    background-repeat: no-repeat;
    display: inline-block;
}

.chat-div_text {
    line-height: 1.95rem;
    color: #374151;
}

.like {
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/like.svg');
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-right: 14px;
    padding: 14px 8px;
}

.dislike {
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/dislike.svg');
    background-repeat: no-repeat;
    cursor: pointer;
    display: inline-block;
    margin-right: 14px;
    padding: 14px 8px;
}

.copy-q {
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/copy.svg');
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-right: 14px;
    padding: 14px 6px;
}

.delete-q {
    background-size: 14px;
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/delete_trash.svg');
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-right: 14px;
    padding: 14px 8px;
}

.delete-a {
    background-size: 14px;
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/delete_trash.svg');
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-right: 14px;
    padding: 14px 8px;
}

.copy {
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/copy.svg');
    background-repeat: no-repeat;
    display: inline-block;
    cursor: pointer;
    margin-right: 14px;
    padding: 14px 6px;
}

.user-image {
    // background-repeat: no-repeat;
    // background-image: url('../../public/img/user2.png');
    background: #ec417a;
    text-align: center;
    width: 32px;
    height: 33px;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    padding: 4px;
    border-radius: 4px;

    p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }

}

.wrapper-container {
    // overflow-y: auto;
    // height: 100vh;
}

@media (max-width: 576px) {
    .wrapper-container {
        max-width: 540px;
    }
}

@media (max-width: 768px) {
    .wrapper-container {
        max-width: 720px;
    }
}

@media (max-width: 992px) {
    .wrapper-container {
        max-width: 960px;
    }
}

@media (max-width: 1200px) {
    .wrapper-container {
        max-width: 1140px;
    }
}

.bg-gray {
    background: #F7F7F8 0% 0% no-repeat padding-box;
    padding: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bg-gray-dark {
    background: #444654;
    padding: 30px 0 30px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.bg-white {
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;
}

.bg-dark {
    background: #343541;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px 0 30px 0;
}

.p-white {
    color: #fff;
}

.bottom-content {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 22px;
}

.bottom-div {
    position: relative;
    // box-shadow: 0px 3px 13px #00000024;
    // border: 1px solid #D5D7E3;
    border-radius: 12px;

    textarea {
        padding-right: 32px;
        padding-top: 18px;
    }

    .dark-textarea {
        background: #444654;
        border: none;
        color: #fff;
    }

}

.input-send:focus-visible {
    outline: none;
}

.input-send {
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 13px #00000024;
    border: 1px solid #D5D7E3;
    border-radius: 12px;
    width: 755px;
    height: 61px;
    padding-left: 26px;

    // position: fixed;
    // bottom: 46px;
    @media (max-width:1060px) {
        width: 615px;
    }

    @media (max-width:940px) {
        width: 500px;
    }

    @media (max-width:530px) {
        width: 450px;
    }

    @media (max-width:454px) {
        width: 400px;
    }

    @media (max-width:413px) {
        width: 360px;
    }
}

.first-col_1 {
    @media (max-width:1130px) {
        display: none;
    }
}

.first-col_2 {
    // display: flex;
    // justify-content: center;
    // align-items: center;

    @media (max-width:1130px) {
        webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}

.col-8 {

    // display: flex;
    // align-items: center;
    p {
        padding: 0;
        margin: 0;
    }

    @media (max-width:767px) {
        width: 88%;
    }
}

.first-col_2 {
    @media (max-width:767px) {
        width: 12%;
    }
}

.col-1-icons {

    // display: flex;
    // justify-content: center;
    // align-items: center;
    @media (max-width:767px) {
        width: 43%;
    }
}

.max-w-767 {
    @media (max-width:767px) {
        max-width: 767px;
    }
}


// form page scss
.form-dashboard {
    width: 100%;

    margin: auto;

    @media (max-width:1399.98px) {
        width: 700px;
    }

    @media (max-width:1140px) {
        width: 100%;
    }
}

.form-container {
    margin-top: 38px;
}

.zeta-form {
    .form-items {
        display: flex;
        flex-direction: column;
        width: 100%;

        .box-shadow-none {
            box-shadow: none;
            outline: none;
        }

        .font-w-700 {
            letter-spacing: 0.15px;
            color: #2D333A;
            font-weight: 700;
        }

        textarea {
            width: 100%;
            border: 1px solid #C2C8D0;
            border-radius: 6px;
        }
    }

    .btn-submit-form {
        background: #10A37F;
        color: #fff;
        width: 150px;
        height: 52px;
        box-shadow: none;
        border-radius: 6px;
        // /* padding: 10px 40px 10px 40px;
    }
}

.title-input {
    border-radius: 6px;
    box-shadow: none;
    letter-spacing: 0.2px;
    width: 320px;
    height: 52px;
    padding-left: 10px;
    outline: none;
    border: 1px solid #ccc;
}

.title-input-select {
    border-radius: 6px;
    box-shadow: none;
    letter-spacing: 0.2px;
    width: 315px;
    height: 52px;
    padding-left: 10px;
    outline: none;
    border: 1px solid #ccc;

    @media (max-width:500px) {
        width: 260px;
    }

    @media (max-width:500px) {
        width: 200px;
    }
}

.header-close {
    justify-content: flex-end;

    .close-icon {
        cursor: pointer;
        background-image: url('../../public/img/Group518.svg');
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        display: inline-block;
        margin-top: 8px;
        border: none;
        background-color: #fff;

        &:focus {
            outline: none;
            border: none;
        }
    }
}

.d-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

// main-wrapper > bg-white > p tag

// ans #444654
// question #343541

.loadingQuestion {
    position: absolute;
    right: 10px;
    top: 20px;
}

.loadingQuestion-dark {
    color: #fff;
    position: absolute;
    right: 10px;
    top: 20px;
}

.dark-wrapper .navbar-1 {
    z-index: 0;
}

.bottom-div form {
    display: flex;
    align-items: center;
}

.btn-bottom {
    width: 10px;
    height: 10px;
    margin-left: 20px;
    border: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
}