.user-form {
    // width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    padding: 10px;
}

.model-li {
    list-style: none;
}

.code-header {
    display: flex;
    background: #343541;
    color: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 15px;

    p {
        font-weight: 100;
        font-size: 15px;
    }

    button {
        background-color: #343541;
        border: none;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        cursor: pointer;
        text-align: center;

        .copy {
            height: 15px;
            padding-left: 0;
            padding-top: 0px;
        }

        p {
            color: #fff;
            font-weight: 100;
            padding-bottom: 1px;
            font-size: 15px;
        }
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    // justify-content: center;
}

.loader-center {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disable {
    display: none;
}


.main-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
}

.p-white {
    color: #fff;
}

.back-dark {
    background: #444654;
    color: #fff;
}

.main-wrapper_dark {
    background: #343541;
}

.dashboard-main-content {
    scrollbar-color: #555555 #f0f0f0;
}

/* The scrollbar track */
.dashboard-main-content::-webkit-scrollbar {
    width: 7px;
    background-color: #f0f0f0;
}

.dashboard-main-content::-webkit-scrollbar-thumb {
    background-color: #555555;
}

.dashboard-main-content::-webkit-scrollbar-thumb:hover {
    background-color: #888888;
}

.dashboard {
    width: 100%;
}

.dashboard-main-content_admin {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: baseline;
    padding: 10px;
}

.dashboard-main-content_user {
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.dashboard-main-content {
    height: 77vh;
    overflow-y: auto;
}

.users-table {
    border: 1px solid #ccc;

    tbody {
        color: black;
        background: #F7F7F8;
    }
}



@media (max-width: 576px) {
    .wrapper-container {
        max-width: 540px;
    }
}

@media (max-width: 768px) {
    .wrapper-container {
        max-width: 720px;
    }
}

@media (max-width: 992px) {
    .wrapper-container {
        max-width: 960px;
    }
}

@media (max-width: 1200px) {
    .wrapper-container {
        max-width: 1140px;
    }
}


.bottom-content {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 22px;
}

@media (max-width: 576px) {
    .bottom-content {
       
        margin-top: -8px;
    }
}


.input-send:focus-visible {
    outline: none;
}

.input-send {
    // background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 13px #00000024;
    border: 1px solid #D5D7E3;
    border-radius: 12px;
    width: 755px;
    height: 61px;
    padding-left: 26px;

    // position: fixed;
    // bottom: 46px;
    @media (max-width:1060px) {
        width: 615px;
    }

    @media (max-width:940px) {
        width: 500px;
    }

    @media (max-width:530px) {
        width: 450px;
    }

    @media (max-width:454px) {
        width: 400px;
    }

    @media (max-width:413px) {
        width: 360px;
    }
}

.first-col_1 {
    @media (max-width:1130px) {
        display: none;
    }
}

.first-col_2 {
    // display: flex;
    // justify-content: center;
    // align-items: center;

    @media (max-width:1130px) {
        webkit-box-flex: 0;
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
    }
}

.col-8 {

    // display: flex;
    // align-items: center;
    p {
        padding: 0;
        margin: 0;
    }

    @media (max-width:767px) {
        width: 88%;
    }
}

.first-col_2 {
    @media (max-width:767px) {
        width: 12%;
    }
}

.col-1-icons {

    // display: flex;
    // justify-content: center;
    // align-items: center;
    @media (max-width:767px) {
        width: 43%;
    }
}

.max-w-767 {
    @media (max-width:767px) {
        max-width: 767px;
    }
}


// form page scss
.form-dashboard {
    width: 100%;

    margin: auto;

    @media (max-width:1399.98px) {
        width: 700px;
    }

    @media (max-width:1140px) {
        width: 100%;
    }
}

.form-container {
    margin-top: 38px;
}

.zeta-form {
    .form-items {
        display: flex;
        flex-direction: column;
        width: 100%;

        .box-shadow-none {
            box-shadow: none;
            outline: none;
        }

        .font-w-700 {
            letter-spacing: 0.15px;
            color: #2D333A;
            font-weight: 700;
        }

        textarea {
            width: 100%;
            border: 1px solid #C2C8D0;
            border-radius: 6px;
        }
    }

    .btn-submit-form {
        background: #10A37F;
        color: #fff;
        width: 150px;
        height: 52px;
        box-shadow: none;
        border-radius: 6px;
        // /* padding: 10px 40px 10px 40px;
    }
}

.title-input {
    border-radius: 6px;
    box-shadow: none;
    letter-spacing: 0.2px;
    width: 320px;
    height: 52px;
    padding-left: 10px;
    outline: none;
    border: 1px solid #ccc;
}

.header-close {
    justify-content: flex-end;

    .close-icon {
        cursor: pointer;
        background-image: url('../../public/img/Group518.svg');
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        display: inline-block;
        margin-top: 8px;
        border: none;
        background-color: #fff;

        &:focus {
            outline: none;
            border: none;
        }
    }
}