.contentClass {
    opacity: 0.4;
}

body {
    overflow-y: hidden;
}

.open-sidebar {
    position: absolute;
    width: 46px;
    height: 46px;
    border: 1px solid #D5D7E3;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    left: 5px;
    top: 7px;
    background: #202123;
    padding: 5px;

    .icon {
        width: 20px;
        height: 20px;
        background-image: url('../../public/img/Group2.svg');
        background-repeat: no-repeat;
        background-size: 19px 19px;
        display: inline-block;
        margin-top: 7px;
    }

    @media (max-width:767px) {
        display: none;
    }
}

.sidebar-btn-w-767 {
    position: absolute;
    width: 46px;
    height: 46px;
    border: 1px solid #D5D7E3;
    border-radius: 3px;
    display: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    right: 10px;
    top: 7px;
    background: #202123;
    padding: 5px;
    z-index: 99;

    @media (max-width:767px) {
        display: flex;
    }

    .icon {
        width: 20px;
        height: 20px;
        background-image: url('../../public/img/Group2.svg');
        background-repeat: no-repeat;
        background-size: 19px 19px;
        display: inline-block;
        margin-top: 7px;
    }
}

.sidebar-w-767 {
    position: absolute;
    z-index: 1;
    min-height: 100vh;
    width: 285px;
    color: #fff;
    background: #202123;
    box-shadow: 0px 30px 288px #ccc;

    @media (max-width:400px) {
        width: 248px;
    }

    .part-one {
        margin-right: 6px;
        margin-left: 10px;

        .chat {
            width: 260px;
        }

        .side-icon {
            display: none;
        }
    }

    .part-two {
        margin-left: 10px;
        margin-right: 10px;

        // input {
        //     width: 100%;
        // }
    }
}

.sidebar {
    height: 100vh;
    min-height: 100vh;
    // width: 21.10%;
    width: 285px;
    color: #fff;
    background: #202123;

    @media (max-width:767px) {
        display: none;
    }
}

.hidden {
    display: none;
}

.hidden.sidebar-w-767 {
    display: block;
}

.hidden.sidebar-w-0 {
    display: none;
}

.sidebar-top {
    // height: 20%;
    height: 17vh;

    a {
        text-decoration: none;
    }
}

.sidebar-menu {
    height: 65vh;
    overflow-y: auto;
    width: 100%;
    padding: 11px;
}

.sidebar-menu {
    scrollbar-color: #555555 #f0f0f0;
}

/* The scrollbar track */
.sidebar-menu::-webkit-scrollbar {
    width: 7px;
    // border-radius: 4px;
    background-color: #202123;
}

.sidebar-menu::-webkit-scrollbar-thumb {
    background-color: #555555;
}

.sidebar-menu::-webkit-scrollbar-thumb:hover {
    background-color: #888888;
}

.sidebar-content {
    // margin-top: 10px;
    // margin-bottom: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.part-one {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: 7px;
    margin-left: 10px;

    .chat {
        margin-right: 10px;
        border: 1px solid #D5D7E3;
        border-radius: 3px;
        display: flex;
        justify-content: unset;
        align-items: center;
        width: 205px;
        height: 46px;
        cursor: pointer;
        background: #202123;
        color: #fff;
        outline: none;
    }

    .new-chat {
        padding-top: 10px;

        // padding: 0;
        p {
            margin: 0;
            padding-bottom: 12px;
            padding-left: 6px;
        }
    }

    .side-icon {
        width: 46px;
        height: 46px;
        border: 1px solid #D5D7E3;
        border-radius: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @media (max-width:767px) {
            // display: none;
        }
    }
}

.part-two {
    position: relative;
    border: 1px solid #D5D7E3;
    border-radius: 3px;
    width: 260px;
    height: 46px;
    margin: auto;
    margin-top: 10px;
    margin-bottom: 10px;

    input {
        background: #202123;
        border: none;
        padding: 10px;
        width: 100%;
        color: #fff;
        position: absolute;

        &:focus-visible {
            border: none;
        }

        &::placeholder {
            color: #fff;
        }
    }

    .search-icon {
        width: 20px;
        height: 20px;
        background-image: url('../../public/img/search-icon.svg');
        background-repeat: no-repeat;
        background-size: 15px 15px;
        display: inline-block;
        z-index: 1;
        right: 8px;
        top: 14px;
        background-size: 17px;
        position: absolute;
        right: 8px;
    }
}



.plus-icon {
    width: 20px;
    height: 12px;
    background-image: url('../../public/img/Add.svg');
    background-repeat: no-repeat;
    background-size: 13px 12px;
    display: inline-block;
    margin-left: 6px;
}

.sidebar-icon {
    width: 20px;
    height: 20px;
    background-image: url('../../public/img/Group2.svg');
    background-repeat: no-repeat;
    background-size: 19px 19px;
    display: inline-block;
}

.sidebar-menu {

    .heading {
        color: #D5D7E3;
        opacity: 0.7;
        padding-left: 7px;

        span {
            font-size: 14px;
        }
    }

    ul {
        margin-left: -41px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .active {
        background: #282A2E;
        border-radius: 5px;
    }

    li {

        position: relative;
        display: flex;
        align-items: center;
        list-style: none;
        cursor: pointer;

        .chat-content {
            padding: 10px;
            display: flex;
            justify-content: space-around;
            align-items: center;
        }

        &:hover {
            background: #282A2E;
            border-radius: 5px;
        }

        .icon {
            margin-top: 8px;
            width: 20px;
            height: 20px;
            background-image: url('../../public/img/message.svg');
            background-repeat: no-repeat;
            display: inline-block;
        }
    }

    .text p {
        margin: 0;
        padding: 0;
        color: #F5F6FA;
        padding-left: 4px;
        font-size: 14.18px;
    }
}

.sidebar-footer {
    background: #202123;
    position: fixed;
    // height: 17vh;
    // width: 285px;
    width: 278px;
    bottom: 0;
    border-top: 1px solid #D5D7E3;
    padding: 10px;

    .icon {
        background-image: url('../../public/img/menu.svg');
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        display: inline-block;
        margin-top: 6px;
    }

    .templete-div {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        cursor: pointer;

        p {
            padding-left: 12px;
            color: #F5F6FA;
            margin: 0
        }

        &:hover {
            background: #282A2E;
            border-radius: 5px;
        }
    }

    .profile-section {
        &:hover {
            background: #282A2E;
            border-radius: 5px;
        }
    }

    .profile-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        cursor: pointer;
        position: relative;

        .text p {
            padding-left: 12px;
            color: #F5F6FA;
            margin: 0
        }

        .profile-user {
            display: flex;
        }

        .option-icon {
            background-image: url('../../public/img/option.svg');
            width: 20px;
            height: 20px;
            background-repeat: no-repeat;
            display: inline-block;
            position: absolute;
            right: 5px;
            top: 22px;
        }



    }

    .user-icon {
        // background-image: url('../../public/img/user2.png');
        width: 30px;
        height: 28px;
        // background-size: 26px;
        background: #ec417a;
        // background-repeat: no-repeat;
        display: inline-block;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        p {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.option {
    display: flex;
    align-items: center;

    .delete {
        background-image: url('../../public/img/delete.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        margin-top: 8px;
        margin-right: 4px;
    }

    .help {
        background-image: url('../../public/img/help.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        margin-top: 1px;
        margin-right: 4px;
        display: inline-block;
    }

    .setting {
        background-image: url('../../public/img/setting.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        margin-top: 8px;
        margin-right: 4px;
        display: inline-block;
    }

    .logout {
        background-image: url('../../public/img/logout.svg');
        background-repeat: no-repeat;
        width: 20px;
        height: 20px;
        margin-top: 8px;
        margin-right: 4px;
        display: inline-block;
    }
}

.option-group {
    position: absolute;
    bottom: 61px;
    right: 0px;
    // border: 1px solid #ccc;
    width: 100%;

    .option-item {
        cursor: pointer;
        background: black;

        // margin-top: 1px;
        // margin-bottom: 1px;
        &:hover {
            background: #282A2E;
        }
    }

    .logout-border {
        margin-top: 2px;
        border-top: 1px solid;
    }
}

.delete {
    background-image: url('../../public/img/delete.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.edit {
    background-image: url('../../public/img/edit.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.share {
    background-image: url('../../public/img/share.svg');
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
}

.actions {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}